//useMedicalFeeList
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useMedicalFeeList() {
    const toast = useToast()
    const refListTable = ref();
    let blankData = {
        start_date:new Date().toISOString().slice(0,10),
        end_date:new Date().toISOString().slice(0,10),
        staff_id:'',
        status:''
    }
    const pricetotal = ref(0);
    const priceprinttotal = ref(0);
    const filterData = ref(JSON.parse(JSON.stringify(blankData)))
    const tableColumns = [
        // { field: 'no', label: '#', sortable: false},
         { field: 'created_at', label: 'วันที่', sortable: false},
         { field: 'name', label: 'ชื่อ', sortable: false},
         { field: 'service_no', label: 'Service No', sortable: false},
         { field: 'price', label: 'ค่ามือ', sortable: false},
         { field: 'status', label: 'สถานะ', sortable: false}
       
 
 
 
     ]
    const pageLength = ref(10);
    const currentPage = ref(1);
    const Total = ref(0);
    const items = ref([]);
    const staffs = ref([]);
    const statuses = ref([{value:0,title:'Waiting Approve'},{value:1,title:'Approved'}]);
    
    const itemsPrint = ref([]);
    watch([currentPage, pageLength], () => {
        items.value = []
        fetchData();
    })
    const ApplyFilter = async ()=>{
        items.value = []
        fetchData();

    }
    const clearFilter = async ()=>{
        let blankDatac = {
            start_date:'',
            end_date:'',
            staff_id:'',
            status:''
        }
        items.value = []
        filterData.value = blankDatac;
        fetchData();

    }
    const fetchData = async ()=>{
        //assistant
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const offset = pageLength.value*(currentPage.value-1);
        let response =  await store.dispatch('app-report/fetchfeeData', {
                // id:queue_id,
                  limit: pageLength.value,
                  offset:offset,
                  types:'doctor',
                  branch_id:userStorage.branch_id,
                  start_date:filterData.value.start_date,
                  end_date:filterData.value.end_date,
                  staff_id:filterData.value.staff_id,
                  status:filterData.value.status

                
                  
              
                });
          if(response.data.success){
            items.value = response.data.data;
           // console.log('price',response.data);
            pricetotal.value = response.data.pricetotal
  
          }
         
        
  
      }
      fetchData();
      const usersData = async ()=>{
        //assistant
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const offset = pageLength.value*(currentPage.value-1);
        let response =  await store.dispatch('app-users/fetchData', { group_id:7});
        console.log('staffs',response);
          if(response.data.success){
            staffs.value = response.data.data;
  
          }
         
        
  
      }
      usersData();
      const changeStatus = async(data)=>{
        let response =  await store.dispatch('app-report/updatefeestatusData', {
                     id:data.id,
                     status:data.status,
     
            
              
          
            });
          console.log('response',response);
            if(response.data.success){
                toast({
                    component: ToastificationContent,
                    props: {
                      title: "Udate Approve Done",
                      icon: 'AlertTriangleIcon',
                      variant: 'success',
                    },
                  })
            }else{
                toast({
                    component: ToastificationContent,
                    props: {
                      title: "Udate Approve Error.",
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })

            }
            items.value = [];
            fetchData();
            
        console.log('changeStatus',data);

      }
      const getPrintData = async()=>{
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        itemsPrint.value = []
        let response =  await store.dispatch('app-report/fetchfeeData', {
            // id:queue_id,
            
            types:'doctor',
                  branch_id:userStorage.branch_id,
                  start_date:filterData.value.start_date,
                  end_date:filterData.value.end_date,
                  staff_id:filterData.value.staff_id,
                  status:filterData.value.status
            
              
          
            });
          
      if(response.data.success){
        itemsPrint.value = response.data.data;
        priceprinttotal.value =  response.data.pricetotal
       

      }

      }
    
    return {
        filterData,
        refListTable,
        tableColumns,
        items,
        pageLength,
        currentPage,
        Total,
        ApplyFilter,
        clearFilter,
        staffs,
        changeStatus,
        statuses,
        pricetotal,
        getPrintData,
        itemsPrint,
        priceprinttotal
        
    }
}