<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row >
                    <b-col
                    md="6"
                    class="d-flex justify-content-start"
                    
                    >
                    <h4 class="text-dark font-weight-bold">Filter</h4>
                    
                    </b-col>
                    <b-col
                    md="6"
                    class="d-flex justify-content-end"
                    >
                    <b-button  
                        type="button"
                        class="ml-1"
                        variant="success"
                        @click="generateReport()"
                            >
                        
                            Print
                        </b-button>
                        <b-button  
                        type="button"
                        variant="primary"
                        class="ml-1"
                        @click="ApplyFilter"
                            >
                            
                            Apply
                        </b-button>
                        <b-button  
                        type="button"
                        class="ml-1"
                        variant="danger"
                        @click="clearFilter"
                            >
                        
                            Clear
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="form-boder-input">
                    <b-col md="3">
                       
                        <div class="demo-vertical-spacing ml-1">
                                <b-form-group
                                label="จากวันที่"
                                label-for="start_date"
                                >
                                <b-form-datepicker
                                    v-model="filterData.start_date"
                                    reset-button

                                    type="date"
                                />
                                </b-form-group>
                            </div>
                    
                    </b-col>
                    <b-col md="3">
                       
                       <div class="demo-vertical-spacing ml-1">
                               <b-form-group
                               label="ถึงวันที่"
                               label-for="end_date"
                               >
                               <b-form-datepicker
                                   v-model="filterData.end_date"
                                   reset-button

                                   type="date"
                               />
                               </b-form-group>
                           </div>
                   
                   </b-col>
                   <b-col md="3">
                       
                       <div class="demo-vertical-spacing ml-1">
                        <b-form-group
                        label="พนักงาน"
                        label-for="keyword"
                        >
                       
      
                         <v-select
                            :options="staffs"
                            v-model="filterData.staff_id"
                            :get-option-label="(option) => (option.first_name+' '+option.last_name)"
                            :reduce="val => val.id"
                            >
                           
                        </v-select>
                        </b-form-group>
                           </div>
                   
                   </b-col>
                   <b-col md="3">
                       
                       <div class="demo-vertical-spacing ml-1">
                        <b-form-group
                        label="สถานะ"
                        label-for="status"
                        >
                       
      
                         <v-select
                            :options="statuses"
                            v-model="filterData.status"
                            :get-option-label="(option) => (option.title)"
                            :reduce="val => val.value"
                            >
                           
                        </v-select>
                        </b-form-group>
                           </div>
                   
                   </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card >
            <b-row>
                <b-col md="12">
                    <h4 class="text-dark font-weight-bold">ค่ามือแพทย์</h4>
                </b-col>
            </b-row>
           
            <vue-good-table
                    :columns="tableColumns"
                    :rows="items"
                
                    ref="refListTable"
                    :select-options="{
                        enabled: false,
                        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    :pagination-options="{
                            enabled: true,
                            perPage:pageLength
                        }"
                
                    
                >
                <template
                        slot="table-row"
                        slot-scope="props"
                >
                <div v-if="props.column.field === 'created_at'">
                    <span v-if="props.row.created_at">
                        {{  formatDateTh(props.row.created_at) }}
                    </span>
                   
                </div>
                <div v-else-if="props.column.field === 'name'">
                   {{  props.row.first_name }}  {{  props.row.last_name }} 
                  
                   
                </div>
                <div v-else-if="props.column.field === 'price'">
                  {{ getCommaDecimal(props.row.price) }}
                   
                </div>
                <div v-else-if="props.column.field === 'status'">
                    <b-form-checkbox 
                    value="1"
                  v-model="props.row.status"
                 @input="changeStatus(props.row)"
                  trim name="check-button" 
                  switch
                />
                   
                </div>
                
                
                </template>
                <div slot="table-actions-bottom" class="p-1"  >
                    <div class="text-right " style="margin-right:25%;">
                       
                          <h4 class="text-black">รวมทั้งหมด <span class="pl-3">{{  getCommaDecimal(pricetotal) }}</span></h4> 
                    </div>
                      
                </div>
                <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                            Items per page
                            </span>
                            <b-form-select
                            v-model="pageLength"
                            :options="['10','20','50']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                        
                        </div>
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="Total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            
                            class="mt-1 mb-0"
                            
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
            </vue-good-table>
        </b-card>
        <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
       
        filename="receipt.js"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        :paginate-elements-by-height="3508"
        :pdf-content-width="2480"
        
        
 
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
        >
        <section slot="pdf-content" class="pdf-content text-black">
            <div class="m-2 text-center">
                <b-img
                    src="@/assets/images/logo/viva-logo.png"
                    alt="logo"
                     style="height:45px;"
                     class="mb-1"
                    />
               
                <h5 class=" text-black">รายงานค่ามือแพทย์</h5>
                <p v-if="filterData.start_date || filterData.end_date">
                วันที่   <span v-if="filterData.start_date">{{ formatDateTh(filterData.start_date) }}</span> -  <span v-if="filterData.end_date">{{ formatDateTh(filterData.end_date) }}</span>
                </p>
            
            </div>
            <section class="pdf-item m-2 text-center "  >
                <table width="100%" style="font-size:10px;" class="text-black" cellpadding="5px" cellspacing="5px">
                    <thead style="margin:0px; padding:0px; border-top:1px solid #000; border-bottom:1px solid #000;">
                        <tr style="margin:0px; padding:0px;">
                            <th class="text-left" style="margin:0px; padding:0px;">วันที่</th>
                            <th class="text-left" style="margin:0px; padding:0px;">ชื่อ</th>
                            <th class="text-left" style="margin:0px; padding:0px;">Service No</th>
                            <th class="text-right" style="margin:0px; padding:0px;">ค่ามือ</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in itemsPrint" :key="'item-'+item.id">
                            <td class="text-left"> {{  formatDateTh(item.created_at) }}</td>
                            <td class="text-left"> 
                                {{  item.first_name }} {{  item.last_name }}
                            </td>
                            
                            <td class="text-left">{{  item.service_no }}</td>
         
                            <td class="text-right">{{  getCommaDecimal(item.price) }}</td>
                          
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3" class="text-right">รวม</td>
                            <td colspan="4" class="text-right">{{  getCommaDecimal(priceprinttotal) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </section>
        </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
///import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateTh,formatDateToMonthShort,getCommaDecimal,getComma } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
//import receiptStoreModule from '../receiptStoreModule'
import useMedicalFeeList from './useMedicalFeeList'
//import html2pdf from 'html2pdf.js'
import VueHtml2pdf from 'vue-html2pdf'
import reportStoreModule from '../reportStoreModule'
import userStoreModule from '../../users/user-list/userStoreModule'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker,
    VueGoodTable,
    vSelect,
    VueHtml2pdf

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            formatDateToMonthShort,
            getCommaDecimal,
            getComma
        }
    },
    setup(props, { emit }){
        const REPORT_STORE_MODULE_NAME = 'app-report';
        if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
        onUnmounted(() => {
            if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
        });
        const USER_STORE_MODULE_NAME = 'app-users'
        
        if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
        onUnmounted(() => {
            if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
        });
        const {
            filterData,
            refListTable,
            tableColumns,
            items,
            pageLength,
            currentPage,
            Total,
            ApplyFilter,
            clearFilter,
            staffs,
            changeStatus,
            statuses,
            pricetotal,
            getPrintData,
            itemsPrint,priceprinttotal
        } = useMedicalFeeList();
        return {
            filterData,
            refListTable,
            tableColumns,
            items,
            pageLength,
            currentPage,
            Total,
            ApplyFilter,
            clearFilter,
            staffs,
            changeStatus,
            statuses,
            pricetotal,
            getPrintData,
            itemsPrint,
            priceprinttotal
        }
    },
    methods:{
        renderSuggestion(suggestion){
           
            return suggestion.item.first_name+' '+suggestion.item.last_name
        },
         async generateReport () {
            await this.getPrintData();
           
            
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(event){
           // console.log('event', event);
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        }
    },
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>